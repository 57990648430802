import request from '@/utils/request'


// 查询签到额外赠送配置列表
export function listGive(query) {
  return request({
    url: '/user/give/list',
    method: 'get',
    params: query
  })
}


export function listCoupons(query) {
  return request({
    url: '/coupon/coupon/type/list',
    method: 'get',
    params: query
  })
}


// 查询签到额外赠送配置分页
export function pageGive(query) {
  return request({
    url: '/user/give/page',
    method: 'get',
    params: query
  })
}

// 查询签到额外赠送配置详细
export function getGive(data) {
  return request({
    url: '/user/give/detail',
    method: 'get',
    params: data
  })
}

// 新增签到额外赠送配置
export function addGive(data) {
  return request({
    url: '/user/give/add',
    method: 'post',
    data: data
  })
}

// 修改签到额外赠送配置
export function updateGive(data) {
  return request({
    url: '/user/give/edit',
    method: 'post',
    data: data
  })
}

// 删除签到额外赠送配置
export function delGive(data) {
  return request({
    url: '/user/give/delete',
    method: 'post',
    data: data
  })
}
