<template>
  <a-drawer width="50%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">


      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="请选择奖品类型">

        <a-select v-model="type"  @change="onChange" placeholder="满天数">
          <a-select-option :value="-1">整月</a-select-option>
          <a-select-option :value="1">填写天数</a-select-option>
        </a-select>
      </a-form-item>


        <a-form-model-item v-if="type==1" :label="'请输入天数'" prop="daysType" >
          <a-input-number placeholder="请输入天数" :min="1" :max="30" v-model="form.daysType"/>
        </a-form-model-item>



      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="请选择赠送类型">
        <a-select v-model="form.giveType"  placeholder="类型">
          <a-select-option value="1">优惠券</a-select-option>
          <a-select-option value="2">经验值</a-select-option>
        </a-select>
      </a-form-item>

      <a-form-model-item v-if="form.giveType==1" :label="'请选择优惠券'" prop="daysType" >
      <a-select placeholder="请选择" v-model="form.couponId" style="width: 100%" allow-clear>
        <a-select-option v-for="(d, index) in couponList" :key="index"
                         :value="d.id">{{ d.couponName }}</a-select-option>
      </a-select>
      </a-form-model-item>

<!--      <a-form-item-->
<!--        v-if="form.giveType==2"-->
<!--        :labelCol="labelCol"-->
<!--        :wrapperCol="wrapperCol"-->
<!--        label="请输入经验值">-->
        <a-form-model-item v-if="form.giveType==2" :label="'请输入经验值'" prop="giveNum" >
        <a-input-number placeholder="请输入经验值" :min="1" v-model="form.giveNum"
        />
        </a-form-model-item>
<!--      </a-form-item>-->





<!--      <a-form-model-item prop="couponId" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('签到额外赠送配置.give_type=1 时优惠券id')}}<template slot="title">{{$t('签到额外赠送配置.give_type=1 时优惠券id')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.couponId"  :placeholder="$t('通用.输入.请输入')+$t('签到额外赠送配置.give_type=1 时优惠券id')" />-->
<!--      </a-form-model-item>-->

      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getGive, addGive, updateGive ,listCoupons} from '@/api/user/give'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";


export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      type:'',
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      couponList:[],
      // 表单参数
      form: {
        id: null,

        daysType: null,

        giveType: null,

        giveNum: null,

        couponId: null,

        createTime: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
          giveNum: [
              { required: true, message: '请输入经验值', trigger: 'blur' }
          ],
          daysType: [
              { required: true, message: '请输入天数', trigger: 'blur' }
          ],
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
    this.getListCoupon()
  },
  methods: {
    getListCoupon(){
      listCoupons({}).then(res=>{
        this.couponList=res.data
      })
    },


      onChange(value) {
          if (value==-1){
              this.form.daysType = value;
          }
      },
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        daysType: null,
        giveType: null,
        giveNum: null,
        couponId: null,
        createTime: null,
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getGive({"id":id}).then(response => {
        this.form = response.data
          this.type= this.form.daysType
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateGive(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addGive(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
